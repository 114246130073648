// Color variables
$colors: (
  education: #FF8C61,
  work: #1A9B94,
  accent: #3182CE,
  text: (
    dark: #2D3748,
    light: #4A5568,
    primary: #333333,
    secondary: #666666
  ),
  tech: (
    blue: #007bff,
    indigo: #6610f2,
    purple: #6f42c1,
    pink: #e83e8c,
    green: #28a745
  ),
  bg: (
    dark: #20232a,
    education: #FFF0E8,
    work: #E0F2F1
  )
);

$sans-serif-font: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin hover-effect {
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

.timeline-node {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  border-radius: 12px;

  @media (max-width: 1024px) {
    gap: 0;
    margin-bottom: 0;
  }

  .timeline-node-content {
    flex-basis: calc(50% - 2rem); // 50% width minus half of the gap
    max-width: calc(50% - 2rem);
    padding: 1rem;
    display: flex;
    flex-direction: column;

    .expand-collapse-button {
      display: none;
    }
    @media (max-width: 1024px) {
    padding: 0;
    }
    .node-card {
      flex-grow: 0.2;
      transition: all 0.3s ease;
      border: 1px solid #e0e0e0;
      border-radius: 12px;
      padding: 1rem;
      position: relative;
    }
  }
  .expanded-content-container {
    flex-basis: calc(50% - 2rem); // 50% width minus half of the gap
    max-width: calc(50% - 2rem);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    @media (max-width: 1024px) {
    padding: 0;
    }
  }

  .expanded-content-container {
    .expanded-content {
      flex-grow: 1;
      padding: 1rem;
      background-color: #faf9f7;
      border: 1px solid #e0e0e0;
      border-radius: 12px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }

  &.present::before {
    content: 'Present';
    background-color: map-get($colors, tech, green);
  }

  .expanded-content {
    .section-title {
      font-size: 1.1rem;
      color: map-get($colors, text, dark);
      font-weight: 600;
    }

    .research-interests {
      .interests-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 0.5rem;

        .interest-item {
          background-color: white;
          border: 1px solid rgba(map-get($colors, accent), 0.2);
          border-radius: 8px;
          padding: 0.5rem;
          @include hover-effect;
          @include flex-center;

          .content {
            display: flex;
            flex-direction: column;

            .interest-header h5 {
              font-size: 1rem;
              font-weight: 600;
              color: map-get($colors, text, dark);
              margin: 0;
            }

            .interest-description {
              font-size: 0.9rem;
              color: map-get($colors, text, light);
              line-height: 1.4;
            }
          }
        }
      }
    }
  }

  .courses-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;

    .course-item {
      font-size: 0.9rem;
      padding: 0.3rem 0;
      color: map-get($colors, text, secondary);
      font-weight: 400;
      border-bottom: 1px solid rgba(map-get($colors, accent), 0.1);
      transition: all 0.2s ease;

      &:last-child { border-bottom: none; }

      &:hover {
        color: map-get($colors, text, primary);
        padding-left: 0.3rem;
      }
    }
  }

  .detail-item {
    margin: 1rem 0;

    .detail-title {
      font-size: 1.1rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
      color: map-get($colors, text, dark);
    }

    p {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
      color: map-get($colors, text, light);
    }

    .detail-skills {
      display: flex;
      flex-wrap: wrap;
      gap: 0.3rem;
      margin-top: 0.5rem;

      .detail-skill {
        font-size: 0.75rem;
        padding: 0.2rem 0.5rem;
        border-radius: 12px;
        background-color: rgba(map-get($colors, accent), 0.1);
        color: map-get($colors, accent);
        font-weight: 500;
        @include hover-effect;

        &:hover {
          background-color: rgba(map-get($colors, accent), 0.2);
        }
      }
    }
  }

  &.work {
    //margin-left: 2rem; // Add gap to the right of the content
    .expand-collapse-button {
        background: map-get($colors, bg, work);

        &:hover {
          background: darken(#FFF5F5, 10%);
        }
      }

    @media (max-width: 1024px) {
      margin-left: 0
    }
    .node-card {
      background-color: map-get($colors, bg, work);
      border-color: map-get($colors, work);
    }

    .expanded-content {
      border-color: map-get($colors, work);
    }
  }

  &.education {
    .expand-collapse-button {
        background: map-get($colors, bg, education);

        &:hover {
          background: darken(#E6F6FF, 10%);
        }
      }

    @media (max-width: 1024px) {
      margin-right: 0
    }
    .node-card {
      background-color: map-get($colors, bg, education);
      border-color: map-get($colors, education);
    }

    .expanded-content {
      border-color: map-get($colors, education);
    }
  }
  }

  .node-header {
    @include flex-center;
    margin-bottom: 10px;

    .icon {
      margin-right: 10px;
      color: map-get($colors, accent);
    }

    h3 {
      margin: 0;
      font-size: 1.2rem;
      color: map-get($colors, text, dark);
      font-weight: 600;
    }

    h4 {
      margin: 0 0 0 10px;
      font-size: 1rem;
      font-weight: normal;
      color: map-get($colors, text, light);
    }
  }

  p {
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: map-get($colors, text, light);
  }

  .skills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;

    .detail-skill {
      font-size: 0.8rem;
      background-color: rgba(map-get($colors, accent), 0.1);
      color: map-get($colors, accent);
      padding: 3px 8px;
      border-radius: 12px;
      @include hover-effect;

      &:hover {
        background-color: rgba(map-get($colors, accent), 0.2);
      }
    }
  }

  .expanded-content {
    background-color: #faf9f7;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }


@media (max-width: 1024px) {
  .timeline-node {
    flex-direction: column !important;
    gap: 0;
    margin-bottom: 1rem;
    .timeline-node-content.expand-collapse-button {
        position: absolute;
        right: 10px;
        bottom: 10px;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        width: 25px;
        height: 25px;
        transition: all 0.3s ease;
        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
        z-index: 1;

        svg {
        color: darkslategrey;
        transition: transform 0.3s ease;
      }

      &:hover {
        background-color: #e0e0e0;
        box-shadow: 0 4px 8px rgba(0,0,0,0.15);
        transform: translateY(-2px);
      }

      &:active {
        transform: translateY(0);
      }

        &[aria-expanded="true"] .expand-icon {
        transform: rotate(45deg); // Rotate icon when expanded
      }
    }

    .timeline-node-content,
    .expanded-content,
    .expanded-content-container {
      //width: 100%;
      max-width: none;
      margin: 0 0 20px 0 !important;
    }

    &::before,
    &.work::before {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
