$mainColor: #e17055;
$width: 768px;
html {
  scroll-behavior: smooth;
}

.resume-container {
  padding-top: 2rem; // Adjust the value as needed
}

//.timeline-container {
//  padding-bottom: 100vh; // Adds extra space at the bottom
//}
//
//.timeline-node:last-child {
//  margin-bottom: 50vh; // Ensures the last node is fully visible
//}

@mixin mobile {
  @media (max-width: #{$width}) {
    @content;
  }
}

body,
html {
  //overflow: hidden;
  //position: fixed;
  //width: 100%;
  //height: 100%;
}
