@import '../../variables';
@import '../../app.scss';

$topbar-bg: #E6F3FF;
$text-color: #1A365D;
$active-color: #2B6CB0;
$inactive-color: #4A5568;
$hover-color: #63B3ED;
$transition-speed: 0.3s;

.topbar {
  width: 100%;
  background-color: $topbar-bg;
  color: $text-color;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: $topbar-height;
  transition: height $transition-speed ease;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;

    .MyName {
      font-size: 24px;
      font-weight: bold;
      text-decoration: none;
      color: $text-color;
      transition: color $transition-speed ease;

      &:hover {
        color: $hover-color;
      }
      @media (max-width: 1024px) {
        display: none;
      }
    }

    .navLinks {
      display: flex;
      align-items: center;
      height: 100%;

      a {
        margin: 0 15px;
        color: $inactive-color;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        position: relative;
        padding: 5px 0;
        transition: color $transition-speed ease;

        &:hover {
          color: $hover-color;
        }

        &.active {
          color: $active-color;
          &::after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $active-color;
            transition: width $transition-speed ease;
          }
        }

        &:not(.active)::after {
          content: '';
          position: absolute;
          bottom: -5px;
          left: 50%;
          width: 0;
          height: 2px;
          background-color: $hover-color;
          transition: width $transition-speed ease, left $transition-speed ease;
        }

        &:hover::after {
          width: 100%;
          left: 0;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .itemContainer {
        display: flex;
        align-items: center;
        margin-left: 20px;
        color: $text-color;
        transition: color $transition-speed ease;

        &:hover {
          color: $hover-color;
        }

        .icon {
          font-size: 18px;
          margin-right: 8px;
        }

        span {
          font-size: 14px;
          font-weight: 500;
        }

        a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: inherit;
        }
      }
    }
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 10px 0;

    .wrapper {
      flex-direction: column;

      .navLinks {
        margin-bottom: 10px;
        flex-wrap: wrap;
        justify-content: center;

        a {
          font-size: 14px;
          margin: 5px 8px;
        }
      }

      .right {
        display: none;
      }
    }
  }
}

body {
  //padding-top: calc(#{$topbar-height} + 20px);
  transition: padding-top $transition-speed ease;
}

section {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
