// variables.scss
$mainFontColor: #f5f5f5;
$accentFontColor: #E4572E;
$accentBackgroundColor: #E4572E;
$mainBackgroundColor: #e9f5f9;
$topBarColor: #ffa07a;
$introFontColor: #808080FF;
$hover-color: #FFFFFF;


$primary-color: #4285f4;
$secondary-color: #0f9d58;
$accent-color: #f4b400;
$text-primary: #333333;
$text-secondary: #666666;
$background-light: #f5f5f5;
$background-dark: #e0e0e0;

// Skill category colors
$color-programming: #4285f4;
$color-ml: #0f9d58;
$color-soft: #f4b400;
$color-language: #db4437;
$color-other: #9e9e9e;

// Update your existing SCSS files to use these variables
// For example, in SkillBubble.scss:

.skill-bubble {
  &.programming-language { background-color: $color-programming; }
  &.ml { background-color: $color-ml; }
  &.soft { background-color: $color-soft; }
  &.language { background-color: $color-language; }
  &.other { background-color: $color-other; }
}

// Similarly, update other components to use these color variables


$topbar-height: 4rem;
$topbar-height-mobile: 4rem;

:root {
  --primary-color: #1a2b3c;
  --secondary-color: #00b4d8;
  --accent-color: #ff6b6b;
  --accent-color-dark: #e05a5a;
  --background-color: white;
  --text-color: #333333;
}
