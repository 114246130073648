.personal-notes {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 60px;

  //margin-top: 4rem;

  h3 {
    color: #1a2b3c;
    font-size: 1.2rem;
    margin-bottom: 15px;
    border-bottom: 2px solid var(--accent-color, #6c757d);
    padding-bottom: 5px;
    font-weight: 600;
  }

  .note-date {
    font-size: 0.9rem;
    color: var(--text-color-light, #6c757d);
    margin-bottom: 5px;
  }

  .note-content {
    font-size: 1rem;
    color: var(--text-color, #343a40);
    line-height: 1.5;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 10px;
    //margin-top: 2rem;

    h3 {
      font-size: 1.1rem;
    }

    .note-content {
      font-size: 0.95rem;
    }
  }
}