@import "../../global";

.publication-table {
  margin: 2rem auto;
  padding: 40px 20px;
  background-color: white;
  //display: flex;
  //font-size: 5rem;
  font-family: "Lucida Console", "Courier New", monospace;
  text-align: center;

  p {
    font-size: 1.5rem;
  }

  @include mobile {
    font-size: 17px;
  }
}
