$timelineColor: #3182CE; // A vibrant blue

:root {
  --color-background: #F7FAFC;
  --color-card-researcher: #E6F6FF;
  --color-card-phd: #FFF5F5;
  --color-text-main: #2D3748;
  --color-text-secondary: #4A5568;
  --color-accent-primary: #3182CE;
  --color-accent-secondary: #ED8936;
  --color-timeline: #CBD5E0;
  --color-skill-tag-bg: #3498db;
  --color-skill-tag-text: #2B6CB0;
  --color-status-present: #48BB78;
  --color-status-past: #A0AEC0;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: 'Inter', Arial, sans-serif;
}

.timeline-container {
  position: relative;
  max-width: 75%;
  padding: 20px 20px;
  margin: 0rem auto;
}
.timeline-container h1 {
  text-align: center;
  margin: 0.8rem 0 2rem;
  font-weight: 600;
  font-size: 1.7rem;
  color: var(--color-text); /* Keep the text color neutral */
}

.timeline-container h1 span {
  position: relative; /* Necessary for positioning the underline */
  display: inline-block;
}

.timeline-container h1 span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px; /* Position the underline slightly below the text */
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #FF6F61, #1A9B94); /* Gradient from peach to teal */
}

.timeline-line {
  position: absolute;
  top: 6rem;
  bottom: 0;
  left: 50%;
  width: 2px;
  background-color: var(--color-timeline);
  border-radius: 15px;
  display: inline-block;
}

.timeline-badge {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  z-index: 2;

  .badge-pill {
    background-color: $timelineColor;
    //color: var(--color-badge-text);
    color: whitesmoke;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 600;
    box-shadow: var(--shadow-card);
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($timelineColor, 10%);
    }
  }
}

.toggle-all-button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px 16px;
    background-color: var(--color-badge);
    color: var(--color-badge-text);
    border: none;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;

  &:hover {
    background-color: darken($timelineColor, 10%);
    //color: whitesmoke;
  }

  &:focus {
    outline: none;
    //color: whitesmoke;
    box-shadow: 0 0 0 2px var(--color-highlight);
  }
}

.timeline-node {
  position: relative;
  //margin-bottom: 60px;
  width: 100%;
  display: flex;
  justify-content: flex-start;

  &.work, &.education {
    justify-content: flex-start;
;
    background: whitesmoke;
  }

  &.education {
    justify-content: flex-start;

  }

  h3 {
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: var(--color-text);
    font-weight: 600;
  }

  &.expanded {
    .timeline-node-content {
      box-shadow: var(--shadow-card), 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    }

    .event-details {
      display: block;
    }
  }
}

.skill-tag {
  background-color: white;
  color: var(--color-text);
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 0.8em;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: beige;
  }

  &.key-skill {
    background-color: var(--color-key-skill-badge);
    color: var(--color-text);
    font-weight: 500;
  }
}

.event-details {
  display: none; // Hide by default
  background-color: var(--color-highlight);
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
  border-top: 1px solid var(--color-card-border);
  transition: all 0.3s ease;

  p {
    margin-bottom: 10px;
    line-height: 1.5;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 10px;

    li {
      margin-bottom: 5px;
    }
  }
}

// Responsive styles
@media (max-width: 1024px) {
  .timeline-container {
    max-width: 95%;
    padding: 20px 20px;
    margin: 0;
  }
  .timeline-header {
    h1 {
          font-size: 1.3rem;
    }
  }
  .timeline-line {
    display: none;
  }
  .toggle-all-button {
    display: none;
  }
}