@import '../../variables';
@import '../../app';

$accentColor: #007bff;
$accentFontColor: #0056b3;

.intro {
  background-color: white;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
     min-height: 0;
    }

  .content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    z-index: 1;

    @media (max-width: 768px) {
      flex-direction: column; // Stack content vertically
      width: 100%;
      padding: 1rem;
    }
  }

  .left-column, .right-column {
    flex: 1;
    padding: 1rem;
  }

  .left-column {
    display: flex;
    flex-direction: column;
    align-items: center;

    .imageContainer {
      width: 350px;
      height: 350px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media (max-width: 768px) {
        width: 150px;
        height: 150px;
      }
    }

    .social-links {
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      a {
        margin: 0 0.5rem;
        color: #2B6CB0;
        transition: color 0.3s ease;

        &:hover {
          color: darken(#2B6CB0, 30%);
        }
      }
    }
  }

  .right-column {

    .main-content {
      .multilingual-greeting {
        margin-bottom: 1.5rem;

        .greeting-line {
          font-size: 1.8rem;
          font-weight: 500;
          margin-bottom: 0.5rem;
          color: #333;

          .colored {
            color: darken(lightsalmon, 15%);
            font-weight: 700;
          }

          &:nth-child(2) {
            font-family: 'Noto Sans SC', Poppins, sans-serif
          }

          &:nth-child(4) {
            font-family: 'Noto Sans JP', Poppins, sans-serif
          }

          @media (max-width: 768px) {
            font-size: 1.3rem;
            margin-bottom: 0.6rem;
            text-align: center;
          }
        }
      }

      h1 {
        text-align: start;
        font-size: 2.5rem;
        margin-bottom: 0.5rem;
        color: darken(lightsalmon, 15%);

        @media (max-width: 768px) {
          font-size: 1.1rem;
        }
      }

      h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        color: #666;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }

      p {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        color: #1a2b3c;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }

      .tagline {
        font-style: italic;
        color: #00b4d8;
        font-size: 1.1rem;
        margin-top: 1rem;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .tech-icons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 1rem;

      img {
        width: 40px;
        height: 40px;
        margin-right: 1rem;
        margin-bottom: 1rem;

        @media (max-width: 768px) {
          width: 30px;
          height: 30px;
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    margin-top: auto;
    padding: 5px;

    //justify-content: space-between;

    //.play-container {
    //  display: none;
    //  width: 50%;
    //  max-width: 50rem;
    //  padding-right: 2rem;
    //}
    .subtle-typewriter-container {
      width: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;

      .subtle-typewriter {
        font-family: 'Roboto Mono', monospace;
        font-size: 0.9rem;
        color: #555;
        line-height: 1.5;
        margin: 0 auto;
        text-align: left;

        span {
          display: block;
          margin-bottom: 0.5rem;
        }
      }}

     @media (max-width: 768px) {
        .subtle-typewriter {
          display: none; // Hide on mobile

          .subtle-typewriter span {
          display: none;
          margin-bottom: 0.5rem;
        }
        }
      }

    @media (max-width: 768px) {
      margin-top: 40px;
      .content-wrapper {
        flex-direction: column;
      }

      .left-column, .right-column {
        width: 100%;
        padding: 1rem;
        font-size: 1rem;
      }

      .left-column .imageContainer {
        width: 200px;
        height: 200px;
      }

      .right-column .main-content {
        h1 {
          font-size: 1.3rem;
        }

        h2 {
          font-size: 1rem;
        }

        p {
          font-size: 1rem;
        }

        .multilingual-greeting .greeting-line {
          font-size: 1rem;
        }
      }

      .bottom {

        .play-container {
          width: 100%;
          margin-bottom: 0.5rem;
        }

        .subtle-typewriter {
          display: none;
        }
      }
    }

    @media (max-height: 667px) {
      .bottom {
        display: none;
      }
    }
  }
}