@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
@import './variables';

:root {
  --color-background: #F7FAFC;
  --color-card-researcher: #E6F6FF;
  --color-card-phd: #FFF5F5;
  --color-text-main: #2D3748;
  --color-text-secondary: #4A5568;
  --color-accent-primary: #3182CE;
  --color-accent-secondary: #ED8936;
  --color-timeline: #CBD5E0;
  --color-skill-tag-bg: #EBF8FF;
  --color-skill-tag-text: #2B6CB0;
  --color-status-present: #48BB78;
  --color-status-past: #A0AEC0;
}

$route-padding: 10px;

body {
  font-family: 'Poppins', sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.topbar {
  color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.routes {
  background-color: white;
  flex-grow: 1;

  .route {
    min-height: 100vh;
    padding: $route-padding;
    transition: background-color 0.3s ease;

    h2 {
      font-weight: 600;
      color: var(--primary-color);
      margin-bottom: 30px;
    }
  }

  .personal-notes-route {
    min-height: auto; // Allow this route to be shorter than 100vh
    padding: $route-padding;
    background-color: var(--background-color-light);
  }
}

// Card Styling
.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
}

// Button Styling
.button {
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: 600;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--accent-color-dark);
  }
}


$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-xlarge: 1200px;

@mixin respond-to($breakpoint) {
  @if $breakpoint == small {
    @media (max-width: $breakpoint-small) { @content; }
  }
  @else if $breakpoint == medium {
    @media (max-width: $breakpoint-medium) { @content; }
  }
  @else if $breakpoint == large {
    @media (max-width: $breakpoint-large) { @content; }
  }
  @else if $breakpoint == xlarge {
    @media (max-width: $breakpoint-xlarge) { @content; }
  }
}

// Responsive adjustments
@include respond-to(medium) {
  .routes {
    scroll-snap-type: none;  // Disable scroll snapping on smaller screens
  }
}

@include respond-to(small) {
  .route, .personal-notes-route {
    padding: $route-padding / 2;
  }

  .personal-notes {
    h3 {
      font-size: 1.1rem;
    }

    .note-content {
      font-size: 0.9rem;
    }
  }
}